'use client'
import React from 'react';
import Link from 'next/link';
import { gruppo } from '@/lib/font';
import { Home } from 'lucide-react';
import type { Dictionary } from '@/lib/dictionary'; // 辞書の型をインポート
import { Locale } from '@/config/i18n.config';

type FooterProps = Readonly<{
    dict: Dictionary;   // dictをpropsで受け取る
    lang: Locale;      // langをpropsで受け取る
}>;

const CURRENT_YEAR = new Date().getFullYear();

const Footer = ({ dict, lang }: FooterProps) => { // asyncを削除
    return (
        <footer className="bg-white shadow-sm mt-8">
            <div className="container mx-auto px-4">
                <div className="flex flex-col md:flex-row items-center justify-between py-6">
                    <div className="flex-shrink-0 mb-4 md:mb-0">
                        <div 
                            className={`${gruppo.className} text-xl md:text-2xl`}
                            style={{
                                textRendering: 'optimizeLegibility',
                                WebkitFontSmoothing: 'antialiased',
                                MozOsxFontSmoothing: 'grayscale',
                            }}
                        >
                            <span>MarketsRank.com</span>
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row items-center">
                        <Link
                            href={`/${lang}`}
                            className="inline-flex items-center justify-center text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300 hover:bg-slate-100 hover:text-slate-900 dark:hover:bg-slate-800 dark:hover:text-slate-50 h-10 px-4 py-2 rounded-md bg-white text-slate-900 shadow-sm border border-slate-200 mb-4 md:mb-0"
                            aria-label={dict.footer.go_home}
                            prefetch={false} // 必要な場合のみtrueに
                        >
                            <Home className="w-4 h-4 md:mr-2" aria-hidden="true" />
                            <span className="hidden md:inline">{dict.footer.go_home}</span>
                        </Link>
                    </div>
                </div>

                <div className="border-t border-gray-200 py-4">
                    <div className="text-center text-sm text-gray-500">
                        <div className="mt-2">
                            © {CURRENT_YEAR} MarketsRank.com All rights reserved.
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

// パフォーマンス最適化のためにメモ化
export default React.memo(Footer);