// components/LanguageSwitcher.tsx
// 'use client' を追加
'use client';

import { Locale } from '@/config/i18n.config';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { ChevronDown } from "lucide-react";

interface LanguageInfo {
  name: string;
  flag: string;
  code: Locale;
}

interface LanguageSwitcherProps {
  currentLang: Locale;
}

const languages: LanguageInfo[] = [
  { name: 'English (US)', flag: '🇺🇸', code: 'en' },
  { name: '日本語', flag: '🇯🇵', code: 'ja' },
  { name: '中文', flag: '🇨🇳', code: 'zh' },
  { name: '繁體中文', flag: '🇭🇰', code: 'zh-HK' },
  { name: 'English (UK)', flag: '🇬🇧', code: 'en-UK' },
  { name: 'Deutsch', flag: '🇩🇪', code: 'de' },
  { name: 'हिन्दी', flag: '🇮🇳', code: 'hi' },
  { name: '한국어', flag: '🇰🇷', code: 'ko' },
  { name: 'Français', flag: '🇫🇷', code: 'fr' },
  { name: 'Svenska', flag: '🇸🇪', code: 'sv' },
  { name: 'Bahasa Indonesia', flag: '🇮🇩', code: 'id' },
  { name: 'العربية', flag: '🇸🇦', code: 'ar' },
  { name: 'Português', flag: '🇧🇷', code: 'pt' },
  { name: 'ไทย', flag: '🇹🇭', code: 'th' },
  { name: 'Bahasa Melayu', flag: '🇲🇾', code: 'ms' },
  { name: 'Italiano', flag: '🇮🇹', code: 'it' },
  { name: 'Türkçe', flag: '🇹🇷', code: 'tr' },
  { name: 'עברית', flag: '🇮🇱', code: 'he' },
  { name: 'Español', flag: '🇪🇸', code: 'es' },
  { name: 'Русский', flag: '🇷🇺', code: 'ru' },
  { name: 'Nederlands', flag: '🇳🇱', code: 'nl' },
  { name: 'Tiếng Việt', flag: '🇻🇳', code: 'vi' },
  { name: 'Dansk', flag: '🇩🇰', code: 'da' },
  { name: 'Polski', flag: '🇵🇱', code: 'pl' },
  { name: 'Suomi', flag: '🇫🇮', code: 'fi' },
  { name: 'Ελληνικά', flag: '🇬🇷', code: 'el' },
  { name: 'Română', flag: '🇷🇴', code: 'ro' },
  { name: 'Magyar', flag: '🇭🇺', code: 'hu' },
  { name: 'Čeština', flag: '🇨🇿', code: 'cs' },
  { name: 'Lietuvių', flag: '🇱🇹', code: 'lt' },
  { name: 'Eesti', flag: '🇪🇪', code: 'et' },
  { name: 'Српски', flag: '🇷🇸', code: 'sr' },
];

export default function LanguageSwitcher({ currentLang }: LanguageSwitcherProps) {
  const pathname = usePathname();
  const currentLanguage = languages.find(lang => lang.code === currentLang);

  // 現在のパス構造を維持しながら言語を切り替える
  const getNewPath = (newLocale: string) => {
    const segments = pathname.split('/');
    segments[1] = newLocale;
    return segments.join('/');
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="sm" className="h-8">
          <span className="mr-2">{currentLanguage?.flag}</span>
          <span className="hidden md:inline mr-1">{currentLanguage?.name}</span>
          <ChevronDown className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[200px] max-h-[400px] overflow-y-auto">
        {languages.map((lang) => (
          <DropdownMenuItem key={lang.code} asChild>
            <Link
              href={getNewPath(lang.code)}
              className={`flex items-center px-2 py-1.5 text-sm ${
                currentLang === lang.code ? 'bg-accent' : ''
              }`}
            >
              <span className="mr-2">{lang.flag}</span>
              <span>{lang.name}</span>
            </Link>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}