'use client'

import { gruppo } from '@/lib/font'
import Link from 'next/link'
import { useState } from 'react'
import { Menu, X } from 'lucide-react'
import { Locale } from '@/config/i18n.config'
import LanguageSwitcher from '@/components/LanguageSwitcher'

type NavigationClientProps = {
    dict: {
        navigation: {
            world_market_info: string;
            institutionalinvestors: string;
            investmentbanks: string;
            hedgefunds: string;
            marketdataanalysis: string;
        };
    };
    lang: Locale;
};

const NavigationClient = ({ dict, lang }: NavigationClientProps) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <nav className="bg-white shadow-sm relative">
            <div className="container mx-auto px-4">
                <div className="flex items-center justify-between h-14">
                    {/* ハンバーガーメニューボタン */}
                    <div className="flex items-center">
                        <button
                            className="md:hidden p-2 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-100 focus:outline-none"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <Menu className="h-6 w-6" />
                        </button>
                    </div>

                    {/* メインコンテンツエリア */}
                    <div className="flex-1 flex items-center justify-between">
                        {/* ロゴと左側のナビゲーションリンク */}
                        <div className="flex items-center space-x-8">
                            <div className="md:flex-shrink-0">
                                <Link href={`/${lang}`} className={`${gruppo.className} hover:opacity-80 transition-opacity`}>
                                    <span className="hidden md:block text-3xl">
                                        MarketsRank.com
                                    </span>
                                </Link>
                            </div>

                            <div className="hidden md:flex items-center space-x-6 pt-1">
                                <Link href={`/${lang}/world-markets-info`} className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-100 transition-all">
                                    {dict.navigation.world_market_info}
                                </Link>
                                <Link href={`/${lang}/marketdata-analysis`} className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-100 transition-all">
                                    {dict.navigation.marketdataanalysis}
                                </Link>
                            </div>
                        </div>

                        {/* モバイル用中央ロゴ */}
                        <div className="absolute left-1/2 transform -translate-x-1/2 md:hidden">
                            <Link href={`/${lang}`} className={`${gruppo.className} hover:opacity-80 transition-opacity`}>
                                <span className="text-2xl">
                                    MarketsRank.com
                                </span>
                            </Link>
                        </div>

                        {/* 言語切り替え（右端に配置） */}
                        <div className="flex items-center">
                        <LanguageSwitcher currentLang={lang} />
                        </div>
                    </div>
                </div>
            </div>

            {/* モバイルメニュー */}
            <div className={`
                fixed top-0 left-0 h-full w-64 bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-50
                ${isOpen ? 'translate-x-0' : '-translate-x-full'}
                md:hidden
            `}>
                <div className="p-4 border-b">
                    <button
                        className="p-2 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-100 focus:outline-none"
                        onClick={() => setIsOpen(false)}
                    >
                        <X className="h-6 w-6" />
                    </button>
                </div>
                <div className="py-4">
                    <Link
                        href={`/${lang}/world-markets-info`}
                        className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                        onClick={() => setIsOpen(false)}
                    >
                        {dict.navigation.world_market_info}
                    </Link>
                    <Link
                        href={`/${lang}/marketdata-analysis`}
                        className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                        onClick={() => setIsOpen(false)}
                    >
                        {dict.navigation.marketdataanalysis}
                    </Link>
                </div>
                <div className="px-4">
                <LanguageSwitcher currentLang={lang} />
                </div>
            </div>

            {isOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
                    onClick={() => setIsOpen(false)}
                />
            )}
        </nav>
    );
}

export default NavigationClient;